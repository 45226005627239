<template>
  <div>
    <gmap-map
      :center="center"
      :options="options"
      :style="mapStyle"
      ref="mapRef"
    >
      <directions-renderer
        v-if="directions"
        :origin="directions.origin"
        :destination="directions.destination"
        :wayPoints="directions.wayPoints"
      />
      <gmap-marker
        v-if="showM"
        :key="index"
        v-for="(m, index) in markers"
        :position="m.position"
        :animation="m.animation"
        :label="m.label"
        :icon="m.icon"
        :z-index="m.zIndex"
        @mouseover="openWindow(m, m.cityId)"
        @mouseout="windowOpen=false"
      ></gmap-marker>
      <!--gmap-info-window
          @closeclick="windowOpen=false"
          :opened="windowOpen"
          :position="infoWindow"
          :options="{
            pixelOffset: {
              width: 0,
              height: -35
            }
          }"
      ><img class="" :src="cityImg" height="120">
      </gmap-info-window-->
    </gmap-map>
  </div>
</template>

<script>
const googleMap  = importVueComp('components/tools', 'GoogleMap', 'custom');
//import location from '@/components/svg/Location';
var pin = require('@/img/location-pin-solid.svg');
import {OverlappingMarkerSpiderfier} from '@/tools/oms.min.js';
import { mapState } from 'vuex';
export default {
  name: "custom-google-map",
  mixins: [googleMap],
  data () {
      return {
          mapStyle: 'width:100%;  height: 390px;',
          strokeColor: 'grey',
          showM: false,
          pinURL: pin,
      }
  },
  computed: {
    ...mapState({
        recordStore: state => state.main.record,
    }),
  },
  methods: {
    markerName (city, dayNr) {
        return `${tr('Day')} ${dayNr}`;
    },
    getOptions () {
      return {
        zoom: 4,
        disableDefaultUI: false,
        scaleControl: true,
        draggable: true,
        navigationControl: true,
        scrollwheel: false,
        mapTypeControl: true,
        styles: [
          {
            featureType: 'water',
            elementType: 'geometry',
            stylers: [{color: '#ffffff'}]
          },
          {
            featureType: 'landscape',
            elementType: 'geometry',
            stylers: [{color: '#DFDDDD'}]
          },
          {
            featureType: "administrative",
            elementType: "labels",
            stylers: [
              { visibility: "off" }
            ]
          },
          {
            featureType: "administrative.province",
            elementType: "geometry.stroke",
            stylers: [
              {
                  color: '#ffffff',
                  lightness: 10
               }
            ]
          },
        ]
      }
    },
    getMarkers () {
        this.showM = false;
        let res = [];
        let markers = [];
        let image = {
            url: this.pinURL,
            scaledSize: {height: 13, width: 13},
            labelOrigin: {x: 10, y: 28},
        }
        if (this.recordStore) {
            let hotelsByDay = this.recordStore.hotelsByDay;
            for (let day of this.recordStore.BookingDays) {
                let cities = day.getCities(hotelsByDay[day.DayNr]);
                if (!cities) continue;
                for (let city of cities) {
                  if (city.MapHidden) continue;
                  if (city && city.Latitude && city.Longitude) {
                      let pos = {lat: city.Latitude, lng: city.Longitude}
                      let marker = {
                          position: pos,
                          label: {
                              text: this.markerName(city, day.DayNr + 1),
                              color: '#000',
                              fontSize: '15px',
                              fontWeight: '700',
                              className: 'marker-label',
                          },
                          icon: _.cloneDeep(image),
                          zIndex: 1,
                          dayNr: day.DayNr + 1,
                          cityId: city.id,
                      };
                      if (this.citiesMark.indexOf(city.id)>-1) {
                        //marker.label.color = 'red';
                        marker.animation = 1;
                      }
                      markers.push(marker);
                  }

                }
            }
        }
        /*for (let cityId of this.bookingCities) {
            let city = this.bookingCitiesObject[cityId];
            if (!city) continue;
            if (city.MapHidden) continue;
            if (city && city.Latitude && city.Longitude) {
                let pos = {lat: city.Latitude, lng: city.Longitude}
                let marker = {
                    position: pos,
                    label: {
                        text: this.markerName(city, city.dayNr),
                        color: '#000',
                        fontSize: '15px',
                        fontWeight: '700',
                        className: 'marker-label',
                    },
                    icon: _.cloneDeep(image),
                    zIndex: 1,
                    dayNr: city.DayNr,
                };
                if (this.citiesMark.indexOf(city.id)>-1) {
                  //marker.label.color = 'red';
                  marker.animation = 1;
                }
                markers.push(marker);
            }
        }*/
        let x = this.options.zoom;
        let k1 = 5.037;
        let k2 = 3.57155;
        let e = 0.1474
        let c = this.getDistance(k1, k2, x, e);

        let newMarkers = this.groupByDistance(markers, c);
        let nl = _.map(_.cloneDeep(newMarkers), (l) => {
            return _.uniq(_.map(l, (r) => {
                return r.dayNr;
            }));
        });
        let newMarkers2 = _.map(newMarkers, (r) => {
            let m = r[0];
            if (r.length>1) {
                let days = _.uniq(_.map(r, (r2) => {
                    return r2.dayNr;
                }));
                //console.log(r, r.length, days, days.length)
                let lat = _.mean(_.map(r, (r2) => {
                    return r2.position.lat;
                }));
                let lng = _.mean(_.map(r, (r2) => {
                    return r2.position.lng;
                }));
                m.position.lat = lat;
                m.position.lng = lng;
                days.sort((a, b) => {return a - b});
                let days2 = [];
                for (let i=0; i<days.length; i++){
                    let d, d2;
                    if (i==0) {
                        d = days[0];
                        d2 = days[1]
                    } else {
                        d = days[i-1];
                        d2 = days[i]
                    }
                    let found;
                    for (let n of nl) {
                        n.sort((a, b) => {return a - b});
                        if (JSON.stringify(n)!=JSON.stringify(days)) {
                            for (let k of n) {
                                if (k>d && k<d2) found = true;
                            }
                        }
                    }
                    if (!found) {
                        //console.log(days2, i, d, d2)
                        days2 = this.addToList(days2, d, d2);
                    } else {
                        if (i==0) {
                            days2.push(d);
                        } else {
                            days2.push(d2);
                        }
                    }
                }
                //days2 = days.join(',');
                m.label.text = `Days ${days2}`;
            }
            return m;
        })
        let newMarkers3 = this.groupByDistance(_.cloneDeep(newMarkers2), c);
        //let newMarkers3 = []
        for (let group of newMarkers3) {
            if (group.length==1) continue;
            group.sort((a, b) => {
                return b.position.lat - a.position.lat;
            })
            for (let i=0; i<group.length; i++){
                let k = c / 2;
                if (i==0) {
                    group[i].position.lat += k;
                } else if (i==1) {
                    //group[i].icon.labelOrigin.y = 28;
                } else {
                    group[i].position.lat += -k;
                }
                group[i].position = Object.assign({}, group[i].position);
                //console.log(group.length, i, group[i].label.text, group[i].icon.labelOrigin.y);
            }
        }
        for (let m of newMarkers2) {
            let nm;
            for (let list of newMarkers3) {
                for (let r of list) {
                    if (r.position.lng == m.position.lng && r.label.text == m.label.text) {
                        nm = r;
                    }
                }
            }
            if (nm) {
                m.position.lat = nm.position.lat;
                m.position = Object.assign({}, m.position);
            }

        }
        let lines = [];
        let t = 0.0 ;
        for (let i in newMarkers2) {
            let m1 = _.cloneDeep(newMarkers2[i]);
            m1.position.lat += -t;
            let m2;
            if (i<newMarkers2.length-1) {
                m2 = _.cloneDeep(newMarkers2[parseInt(i)+1]);
                m2.position.lat += -t;
                let curve = this.updateCurveMarker(m1.position, m2.position);
                if (curve) {
                    lines.push(this.updateCurveMarker(m1.position, m2.position))
                }
            }
        }
        for (let line of lines) {
            newMarkers2.push(line);
        }
        this.showM = true;
        return newMarkers2;
    },
    addToList (list, d, d2) {
        let dn = `${d}-${d2}`;
        if (list.indexOf(dn)==-1) {
            let p = true;
            if (list.length>0) {
                let last = list[list.length-1].toString();
                if (last) {
                    let pair = last.split('-');
                    if (pair.length>1) {
                        if (pair[1] && parseInt(pair[1])==parseInt(d)) {
                            list.pop();
                            list.push(`${pair[0]}-${d2}`);
                            p = false;
                        }
                    } else {
                        if (parseInt(last)==parseInt(d)) {
                            list.pop();
                            list.push(`${last}-${d2}`);
                            p = false;
                        }
                    }
                }
            }
            if (p) list.push(dn);
        }
        return list;
    },
    groupByDistance (markers, distance) {
      let newMarkers = [];
      // Scale factor based on zoom level - made more restrictive
      const zoomLevel = this.options.zoom;

      let k = 13;
      if (markers.length > 20) k = 12;
      const scaleFactor = Math.max(0.5, (20 - zoomLevel) / k);

      for (var i = 0; i < markers.length; i++) {
        var m = markers[i];
        var newGroup = [m];

        for (var j = i + 1; j < markers.length; j++) {
          var n = markers[j];

          // Calculate direct distance
          let d = Math.sqrt(Math.pow(n.position.lat - m.position.lat, 2) + Math.pow(n.position.lng - m.position.lng, 2));
          
          // Scale the latitude and longitude thresholds based on zoom level
          let latD = (distance / 2) * scaleFactor;
          let lngD = (distance * 4) * scaleFactor;
          
          let add = false;
          if (Math.abs(n.position.lat - m.position.lat) < latD) {
              if (Math.abs(n.position.lng - m.position.lng) < lngD) {
                  add = true;
              }
          }
          if (d <= distance * scaleFactor) add = true;
          
          if (add) {
            newGroup.push(n);
            markers.splice(j, 1);
            j--;
          }
        }

        newMarkers.push(newGroup);
      }

      return newMarkers;
    },
    getDistance (k1, k2, x, e) {
        return k1 - k2 * Math.pow(x, e);
    }



  },
};
</script>
