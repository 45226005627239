<template>
    <div class="col-md-12">
        <div v-for="pType of getPaxTypesByBase" >
            <div class="form-row" v-if="totals && totals[baseId] && totals[baseId][pType] && recordStore.Totals[baseId]">
                <div class="col form-group ml-4">
                    <input v-model="recordStore.Totals[baseId][pType].Selected" type="checkbox"
                        class="form-check-input" :disabled="!canEdit" @change="setSelected"/>
                    <label v-if="totals[baseId][pType].Rooms">{{pType}} {{tr('Room')}} {{getRoomsNames(totals[baseId][pType].Rooms)}} </label>
                    <label v-else>{{pType}} </label>
                </div>
                <div class="col">
                    <number-input
                        :changeStyle="true"
                        v-if="recordStore.totals[baseId][pType]"
                        :addClass="recordStore.Totals[baseId][pType].OverPrice? 'border-warning': ''"
                        :currency="recordStore.Currency"
                        :label="recordStore.CurrencyId"
                        :disabled="!canEdit || !canOverwrite(pType)"
                        @change="change($event, baseId, pType, 'Price')"
                        :v.sync="recordStore.totals[baseId][pType].Price"></number-input>
                </div>
                <div class="col" v-if="recordStore.CurrencyId!=recordStore.PriceCurrencyId">
                    <number-input
                        :changeStyle="true"
                        :label="recordStore.PriceCurrencyId"
                        :v="getTotalInCurrency(recordStore.totals[baseId][pType].CalculatedPrice)"
                        :currency="recordStore.PriceCurrency"
                        disabled="true"
                    ></number-input>
                </div>
                <div class="col"    >
                    <number-input
                        :changeStyle="true"
                        :currency="recordStore.PriceCurrency"
                        :label="recordStore.PriceCurrencyId + ' (Set Price)'"
                        :disabled="!canEdit || !canViewSetPrice""
                        @change="setSoftOverPrice($event, baseId, pType)"
                        :v.sync="recordStore.Totals[baseId][pType].SoftOverPriceCurrencyId">
                    </number-input>
                </div>

                <div class="col" v-if="recordStore.Agency && recordStore.Agency.Code == 'IND-INF'">
                    <number-input
                        :changeStyle="true"
                        :currency="recordStore.PriceCurrency"
                        :label="recordStore.PriceCurrencyId + ' (Ind. Inf.)'"
                        :disabled="!canEdit"
                        @change="change($event, baseId, pType, 'AgencyTotal')"
                        :v.sync="recordStore.Totals[baseId][pType].AgencyTotal">
                    </number-input>
                </div>
                <div class="col">
                    <number-input
                        label="Pax. Quant."
                        :v.sync="recordStore.Totals[baseId][pType].Pax" d="0"
                        @change="change($event, baseId, pType, 'Pax')"
                        :disabled="!canEdit"
                     ></number-input>
                </div>
                <div class="col">
                    <number-input
                        label="Pax. in Rooms"
                        :v.sync="recordStore.Totals[baseId].PaxRooms" d="0"
                        @change="change($event, baseId, pType, 'PaxRooms')"
                        :disabled="!canEdit"
                     ></number-input>
                </div>
                <div class="col-md-3 form-group form-default">
                    <input v-model="recordStore.Totals[baseId][pType].Comment" class="form-control"
                        :class="{'fill': recordStore.Totals[baseId][pType].Comment}"
                        type="text" :disabled="!canEdit">
                        <span class="form-bar"></span>
                        <label class="float-label">
                                {{tr('Comment')}}
                        </label>
                    </input>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
const bookingQuoteTotal = importVueComp('components/booking', 'BookingQuoteTotal', 'custom');
var EventBus = require('@/tools/event-bus').default;
export default {
    name: 'custom-booking-quote-total',
    mixins: [bookingQuoteTotal],
    computed: {
        canViewSetPrice () {
            return tools.canAccess(api.currentUser, 'api','GET', 'set_soft_over_price', false);
        }
    },
    methods: {
        async change (value, baseId, pType, fieldName) {
            console.log(fieldName, fieldName == 'PaxRooms');
            if (fieldName == 'PaxRooms') {
                console.log(10)
                await this.recordStore.Totals[baseId].setValue({fieldName, value});
                await this.recordStore.Totals[baseId][pType].setValue({fieldName: 'Pax', value: this.recordStore.Totals[baseId][pType].Pax});
            } else {
                console.log(11)
                await this.recordStore.Totals[baseId][pType].setValue({fieldName, value});
            }
            if (fieldName == 'Pax') {
                this.recordStore.forceHotelGetters = !this.recordStore.forceHotelGetters;
            }
            EventBus.$emit('update-totals');
            EventBus.$emit('update-quotes');
            EventBus.$emit('update-quotes-hotels');
        },
        setSoftOverPrice (value, baseId, pType) {
            let softOverPrice = this.recordStore.currencyConvert(this.recordStore.PriceCurrencyId, this.recordStore.CurrencyId, value);
            this.recordStore.Totals[baseId][pType].SoftOverPrice = softOverPrice;
            EventBus.$emit('update-totals');
            EventBus.$emit('update-quotes');
            EventBus.$emit('update-quotes-hotels');
        },
        currencyConvert (value) {
            return this.recordStore.currencyConvert(this.recordStore.CurrencyId, this.recordStore.PriceCurrencyId, value);
        }
    }
}
</script>

