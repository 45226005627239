<script>
const bookingWindow = importVueComp('components/booking', 'BookingWindow', 'custom');
var EventBus = require('@/tools/event-bus').default;
export default {
    name: 'custom-booking-window',
    mixins: [bookingWindow],
    data () {
        return {
            openNotes: false
        }
    },
    methods: {
        getRouteName (currentRoute) {
            let routeName = bookingWindow.methods.getRouteName(currentRoute);
            if (currentRoute.name == 'booking-catalogue') routeName = 'abm-booking-catalogue';
            return routeName;
        },
        async runSave () {
            if (this.version) {
                /*if (this.user.UserName != 'admin') return;  
                let rec = _.cloneDeep(this.recordStore);
                this.recordStore.clean(rec);
                let r = tools.plainObject(rec);
                let res = await api.post('/api/booking/update_version/' + this.versionId, JSON.stringify(r));
                if (res) {
                    alert(res)
                    return;
                }*/
                return;
            };
            if (this.recordBlocked) {
                //alert(this.recordBlocked);
                //return;
            }
            this.openNotes = false;
            if (this.recordStore.Status == 'CANCELED' && this.recordStore._oldStatus != 'CANCELED')  {
                let payments = _.filter(api.payments, (r) => {
                    return r.PaymentStatus = 'PENDING'
                });
                let receipts = _.filter(api.receipts, (r) => {
                    return r.PaymentStatus = 'PENDING'
                });
                if (payments.length > 0 || receipts.length > 0) {
                    api.selectYesNot = {
                        text: tr('Do you want to cancel pending payments and receipts?'),
                        yes: () => { 
                            this.recordStore._cancelPending = true;
                            this.runSave2();
                        },
                        not: () => {
                            this.recordStore._cancelPending = false;
                            this.runSave2();
                        }
                    }
                    return;
                }
            };
            if (this.recordStore.Status == 'CONFIRMED' && this.recordStore._oldStatus != 'CONFIRMED' && this.recordStore._oldStatus != 'QUOTE')  {
                this.openNotes = true;
            }
            this.runSave2();
        },
        async runSave2 () {
            this.errors = [];
            if (this.recordStore.hasErrors && !this.recordStore.checkFields()) {
                this.alertsNextSave = true;
                this.showBookingAlerts();
            } else {
                await this.save();
            }
        },
        afterSave () {
            EventBus.$emit('after-save', true);
            if (this.openNotes) {
                alert('Booking back to confirmed. Please add a note explaining changes');
                this.showNotes();
            }
        },

    }
}
</script>
